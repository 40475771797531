import React, {
	FunctionComponent,
	PropsWithChildren,
	useCallback,
	useEffect,
	useState
} from 'react';
import { LeagueText, LeagueTextLight } from './Text';
import {
	StyleSheet,
	ImageBackground,
	View,
	SafeAreaView,
	TouchableOpacity,
	Image,
	useWindowDimensions,
	Modal,
	ActivityIndicator,
	Text,
	Platform,
	Dimensions
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Asset } from 'expo-asset';

import { customAlphabet } from 'nanoid/non-secure';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import PlayerScore from './PlayerScore';
import StickButton from './StickButton';
import { getCurrentPlayer, getHandScore, getWinner } from './util';
import Hand from './Hand';
import { useAppSelector, useAppDispatch } from './store';
import { actions, actions as gameActions, PlayerPayload, GameType } from './gameSlice';
import MainMenu from './MainMenu';
import CharacterSelect from './CharacterSelect';
import CharacterMatchup from './CharacterMatchup';
import FriendMenu from './FriendMenu';
import EnterCode from './EnterCode';
import GameOver from './GameOver';
import Deck from './Deck';
import cards from './cards';
import Animated, {
	withTiming,
	useAnimatedStyle,
	withSpring,
	withSequence,
	useSharedValue,
	withDelay,
	runOnJS
} from 'react-native-reanimated';
import Avatar from './Avatar';
import { useSounds } from './useSounds';
import HowToPlay from './HowToPlay';
import CardsRemaining from './CardsRemaining';
import Promo from './Promo';

import gameBackgroundImage from '../assets/images/background.png';
import backArrowImage from '../assets/images/backArrow.png';
import closeImage from '../assets/images/close.png';
import checkIcon from '../assets/images/check.png';
import skullIcon from '../assets/images/skull.png';
import rotateImage from '../assets/images/rotate.png';
import soundOnIcon from '../assets/images/soundOn.png';
import soundOffIcon from '../assets/images/soundOff.png';
import backImage from '../assets/images/cards/back/Cards-Back.png';

const playerIdGenerator = customAlphabet('ABCDEFGHIJKLMNOP123456789', 6);

SplashScreen.preventAutoHideAsync();

interface GameShellProps extends PropsWithChildren {
	showBack?: boolean;
	onBack?(): void;
	full?: boolean;
	scale?: boolean;
}

type MenuScreen = 'MAIN' | 'FRIEND' | 'ENTER_CODE' | 'CHARACTER_SELECT' | 'HOW_TO_PLAY' | 'PROMO';

function cacheImages(images: string[] | number[]) {
	return images.map((image) => {
		if (typeof image === 'string') {
			return Image.prefetch(image);
		} else {
			return Asset.fromModule(image).downloadAsync();
		}
	});
}

const isWeb = Platform.OS === 'web';

const GameShell: FunctionComponent<GameShellProps> = ({
	children,
	showBack = true,
	onBack,
	full = false,
	scale = true
}) => {
	const { height, width } = useWindowDimensions();
	const scaleUp = scale && height > 650;
	const shouldRotate = (isWeb && width < 700) || (!isWeb && width < height);

	const maxHeight = Math.min(height / (scaleUp ? 1.5 : 1), isWeb ? 350 : 600);
	const maxWidth = Math.min(width / (scaleUp ? 1.5 : 1), 900);

	return (
		<ImageBackground
			style={[styles.backgroundImage, scaleUp && isWeb && styles.backgroundImageWebScaledUp]}
			source={gameBackgroundImage}
			resizeMode="repeat"
		>
			{shouldRotate ? (
				<View style={styles.rotateWrapper}>
					<Image source={rotateImage} style={styles.rotateImage} />
					<LeagueTextLight size="large" style={{ textAlign: 'center' }}>
						{isWeb
							? 'Please rotate your device or increase your browser size'
							: 'Please rotate your device'}
					</LeagueTextLight>
				</View>
			) : (
				<SafeAreaView
					style={[
						styles.container,
						{ transform: [{ scale: scaleUp ? 1.5 : 1 }] },
						{
							maxHeight: full ? undefined : maxHeight,
							maxWidth
						}
					]}
				>
					<View style={[styles.gameContent]}>
						{showBack && (
							<TouchableOpacity style={styles.back} onPress={onBack}>
								<Image
									accessibilityLabel="Back"
									style={{ width: '100%', height: '100%' }}
									source={backArrowImage}
								/>
							</TouchableOpacity>
						)}
						{children}
					</View>
				</SafeAreaView>
			)}
		</ImageBackground>
	);
};

const requiredPlayersOnDevice: Record<GameType, number> = {
	OFFLINE_MULTI_PLAYER: 2,
	OFFLINE_SINGLE_PLAYER: 1,
	JOIN_ONLINE_PRIVATE: 1,
	HOST_ONLINE_PRIVATE: 1,
	ONLINE_PUBLIC: 1
};

const hasEnoughPlayers = (
	gameType: GameType | undefined,
	numberOfPlayers: number
): gameType is GameType => {
	if (gameType !== undefined) {
		return requiredPlayersOnDevice[gameType] === numberOfPlayers;
	}
	return false;
};

const loadingQuips = [
	'Gathering requirements',
	'Negotiating with stakeholders',
	'Booking conference room',
	'Initializing middle out compression',
	'Moving sharpies away from dry erase board',
	'Loading Alex Russell tweets',
	'Establishing skynet',
	'Defragmenting harddrive',
	'Creating project board swim lanes',
	'Disrupting planning meeting',
	'Refilling snack and soda supplies',
	'Replacing dry erase markers',
	'Eating a burrito'
];

const Game = () => {
	const game = useAppSelector((state) => state.game);
	const dispatch = useAppDispatch();
	const [menuScreen, setMenuScreen] = useState<MenuScreen>('MAIN');

	const [selectedPlayers, setSelectedPlayers] = useState<PlayerPayload[]>([]);
	const [selectedGameType, setSelectedGameType] = useState<GameType>();

	const {
		status,
		players,
		currentCard,
		handStartCard,
		turnStatus,
		deviceId,
		type,
		targetDeviceId
	} = game;

	const currentPlayer = getCurrentPlayer(game);
	const isMyTurn = currentPlayer && deviceId === currentPlayer.deviceId;
	const buttonsDisabled = !isMyTurn || turnStatus === 'ENDED' || turnStatus === 'ENDING';
	const handScore = getHandScore(game);
	const remainingCards = cards.length - currentCard;
	const [endGameAnimationStep, setEndGameAnimationStep] = useState('');
	const [muted, setMuted] = useState(false);
	const { height } = useWindowDimensions();
	const isMobileWebSized = height < 350;

	const turnTransitionScale = useSharedValue(0);
	useEffect(() => {
		if (turnStatus === 'STARTED') {
			turnTransitionScale.value = withSequence(
				withSpring(1, { stiffness: 150 }),
				withDelay(500, withTiming(0, { duration: 250 }))
			);
		} else {
			turnTransitionScale.value = 0;
		}
	}, [turnStatus]);

	useEffect(() => {
		if (status === 'STARTING') {
			setTimeout(() => {
				dispatch(actions.startGameTransitionEnd());
			}, 2000);
		}
	}, [status]);

	const bannerScale = useSharedValue(0);
	const contentOpacity = useSharedValue(1);

	useEffect(() => {
		switch (endGameAnimationStep) {
			case 'scale': {
				bannerScale.value = withSequence(
					withSpring(1, { stiffness: 250 }),
					withDelay(
						2000,
						withTiming(0, { duration: 250 }, () => {
							runOnJS(setEndGameAnimationStep)('fade');
						})
					)
				);

				break;
			}
			case 'fade': {
				const endGameAction = actions.endGameTransitionEnd();
				contentOpacity.value = withTiming(0, { duration: 500 }, () => {
					runOnJS(dispatch)(endGameAction);
				});
				break;
			}
		}
	}, [endGameAnimationStep]);

	useEffect(() => {
		if (status === 'ENDING') {
			setEndGameAnimationStep('scale');
		} else {
			bannerScale.value = 0;
			contentOpacity.value = 1;
		}
	}, [status]);

	const turnTransitionStyle = useAnimatedStyle(() => {
		return {
			transform: [
				{
					scale: turnTransitionScale.value
				}
			]
		};
	});

	const endingBannerStyle = useAnimatedStyle(() => {
		return {
			transform: [
				{
					scale: bannerScale.value
				}
			]
		};
	});

	const endingContentStyle = useAnimatedStyle(() => {
		return {
			opacity: contentOpacity.value
		};
	});

	const onMenuBack = useCallback(() => {
		dispatch(gameActions.reset());
		setMenuScreen('MAIN');
		setSelectedGameType(undefined);
		setSelectedPlayers([]);
	}, []);

	const onGameBack = useCallback(() => {
		dispatch(gameActions.requestEndGame());
		setMenuScreen('MAIN');
		setSelectedGameType(undefined);
		setSelectedPlayers([]);
	}, []);

	const onShowPromo = useCallback(() => {
		dispatch(gameActions.reset());
		setMenuScreen('PROMO');
	}, []);

	const onRematch = useCallback(() => {
		let gameCode;
		if (type === 'JOIN_ONLINE_PRIVATE') {
			gameCode = targetDeviceId;
			setMenuScreen('CHARACTER_SELECT');
		} else if (type === 'HOST_ONLINE_PRIVATE') {
			gameCode = deviceId;
		}
		dispatch(actions.reset());
		dispatch(
			gameActions.create({
				gameType: selectedGameType!,
				gameCode,
				players: selectedPlayers,
				rematch: true
			})
		);
	}, [type, targetDeviceId, deviceId, selectedGameType, selectedPlayers]);

	const [fontsLoaded] = useFonts({
		samdan: require('../assets/fonts/Samdan.ttf'),
		league: require('../assets/fonts/LeagueGothic-Regular.ttf')
	});

	const [sounds] = useSounds();

	const [imagesLoaded, setImagesLoaded] = useState(false);

	const fetchMutedPref = async () => {
		const muted = await AsyncStorage.getItem('muted');
		setMuted(muted === 'true');
	};

	useEffect(() => {
		async function preloadCardImages() {
			try {
				const cardImages = cards.map((card) => card.image);
				const imageAssets = cacheImages([backImage, ...cardImages]);
				await Promise.all(imageAssets);
			} catch (e) {
				console.warn(e);
			} finally {
				setImagesLoaded(true);
			}
		}
		fetchMutedPref();
		preloadCardImages();
	}, []);

	const requiredAssetsLoaded = fontsLoaded && sounds && imagesLoaded;

	const [loadingQuipIndex, setLoadingQuipIndex] = useState(
		Math.floor(Math.random() * loadingQuips.length)
	);

	useEffect(() => {
		if (requiredAssetsLoaded) {
			SplashScreen.hideAsync();
		} else {
			const timeout = setTimeout(() => {
				setLoadingQuipIndex(
					(index) => (index + 1 + loadingQuips.length) % loadingQuips.length
				);
			}, 1500);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [requiredAssetsLoaded, loadingQuipIndex]);

	useEffect(() => {
		if (currentCard > 0 && !muted) {
			sounds?.drawCard.replayAsync();
		}
	}, [currentCard]);

	const onMayhemCard = useCallback(() => {
		!muted && sounds?.mayhemCard.replayAsync();
	}, [sounds, muted]);

	const onBust = useCallback(() => {
		!muted && sounds?.bust.replayAsync();
	}, [sounds, muted]);

	if (!requiredAssetsLoaded) {
		return (
			<GameShell showBack={false}>
				<View style={[styles.container, styles.loading]}>
					<ActivityIndicator size="large" color="white" />
					<Text style={styles.loadingText}>LOADING</Text>
					<Text style={styles.loadingQuipText}>{loadingQuips[loadingQuipIndex]}</Text>
				</View>
			</GameShell>
		);
	}

	const winner = getWinner(game);

	if (status === 'STARTED' || status === 'ENDING' || status === 'ENDED' || status === 'ABORTED') {
		return (
			<GameShell showBack={false}>
				<View style={styles.root}>
					<View style={styles.header}>
						{isMobileWebSized && status !== 'ENDED' && status !== 'ABORTED' && (
							<View style={styles.cardsLeftFloating}>
								<CardsRemaining
									key="cards-remaining"
									remainingCards={remainingCards}
									showTimer={currentPlayer.status === 'INACTIVE'}
								/>
							</View>
						)}
						{players.map((player, index) => {
							const playerActive = currentPlayer?.playerId === player.playerId;
							return (
								<PlayerScore
									key={`playerScore-${index}`}
									totalScore={player.score}
									handScore={handScore}
									name={player.name}
									side={index ? 'trailing' : 'leading'}
									active={playerActive}
								/>
							);
						})}
						{status !== 'ENDED' && status !== 'ABORTED' && (
							<View style={styles.inGameButtons}>
								<TouchableOpacity
									onPress={() => {
										setMuted((muted) => {
											const newMuted = !muted;
											AsyncStorage.setItem('muted', `${newMuted}`);
											return newMuted;
										});
									}}
								>
									<Image
										source={muted ? soundOffIcon : soundOnIcon}
										style={styles.soundIcon}
									/>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										dispatch(actions.pause());
									}}
									style={styles.close}
								>
									<Image
										source={closeImage}
										style={{ width: '100%', height: '100%' }}
									></Image>
								</TouchableOpacity>
							</View>
						)}
						<Modal
							transparent
							visible={currentPlayer.status === 'PAUSED'}
							supportedOrientations={['landscape']}
						>
							<View style={[styles.overlay, styles.overlayOpacity]}>
								<View style={styles.modal}>
									<Image source={skullIcon} style={styles.modalIcon} />
									<LeagueTextLight>
										Are you sure you want to quit the match?
									</LeagueTextLight>
									<View style={styles.buttonRow}>
										<TouchableOpacity
											style={styles.button}
											onPress={() => {
												dispatch(actions.resume());
											}}
										>
											<LeagueText size="small">Keep playing</LeagueText>
										</TouchableOpacity>
										<TouchableOpacity
											onPress={onGameBack}
											style={[styles.button, styles.buttonFilled]}
										>
											<LeagueTextLight size="small">
												Quit match
											</LeagueTextLight>
										</TouchableOpacity>
									</View>
								</View>
							</View>
						</Modal>
					</View>
					<Animated.View style={[styles.avatarWrapper, turnTransitionStyle]}>
						<Avatar name={currentPlayer.name} size="large" />
						<LeagueTextLight>{`${currentPlayer.name}'s turn`}</LeagueTextLight>
					</Animated.View>
					{status !== 'ENDED' && status !== 'ABORTED' && (
						<>
							<Animated.View style={[styles.content, endingContentStyle]}>
								<Deck
									currentCard={currentCard}
									disabled={buttonsDisabled}
									onPress={() => {
										dispatch(gameActions.requestHit());
									}}
								/>
								<Hand
									key={`hand-${handStartCard}`}
									seed={game.seed!}
									start={handStartCard}
									end={currentCard}
									animateOut={turnStatus === 'ENDING'}
									onBust={onBust}
									onMayhemCard={onMayhemCard}
									onAnimatedOut={() => {
										dispatch(gameActions.nextTurnTransitionEnd());
									}}
								/>
								{status === 'ENDING' && (
									<View style={styles.overlay}>
										{winner ? (
											<Animated.View
												style={[
													styles.banner,
													styles.success,
													endingBannerStyle
												]}
											>
												<Image
													source={checkIcon}
													style={styles.bannerIcon}
												/>
												<LeagueTextLight size="large">
													Milestone Complete!
												</LeagueTextLight>
											</Animated.View>
										) : (
											<Animated.View
												style={[
													styles.banner,
													styles.fail,
													endingBannerStyle
												]}
											>
												<Image
													source={skullIcon}
													style={styles.bannerIcon}
												/>
												<LeagueTextLight size="large">
													Objective failed
												</LeagueTextLight>
											</Animated.View>
										)}
									</View>
								)}
							</Animated.View>
							<Animated.View
								style={[
									styles.footer,
									isMobileWebSized && styles.floatingFooter,
									endingContentStyle
								]}
							>
								{!isMobileWebSized && (
									<CardsRemaining
										key="cards-remaining"
										remainingCards={remainingCards}
										showTimer={currentPlayer.status === 'INACTIVE'}
									/>
								)}
								<StickButton
									handScore={handScore}
									disabled={buttonsDisabled}
									onPress={() => {
										dispatch(gameActions.requestStick());
									}}
								/>
							</Animated.View>
						</>
					)}
					{status === 'ENDED' && (
						<View style={styles.content}>
							<GameOver
								onContinue={onShowPromo}
								type={winner?.deviceId === deviceId ? 'WIN' : 'LOSE'}
								onAction={onRematch}
								action={
									selectedGameType === 'ONLINE_PUBLIC' ||
									selectedGameType === 'OFFLINE_SINGLE_PLAYER'
										? 'play-again'
										: 'rematch'
								}
							/>
						</View>
					)}
					{status === 'ABORTED' && (
						<View style={styles.content}>
							<GameOver
								onContinue={onShowPromo}
								onAction={onRematch}
								type="EARLY"
								action={
									selectedGameType === 'ONLINE_PUBLIC' ||
									selectedGameType === 'OFFLINE_SINGLE_PLAYER'
										? 'play-again'
										: 'rematch'
								}
							/>
						</View>
					)}
				</View>
			</GameShell>
		);
	} else if (
		(status === 'WAITING_FOR_PLAYERS' && menuScreen === 'CHARACTER_SELECT') ||
		status === 'STARTING'
	) {
		let gameCode;
		if (type === 'JOIN_ONLINE_PRIVATE') {
			gameCode = targetDeviceId;
		} else if (type === 'HOST_ONLINE_PRIVATE') {
			gameCode = deviceId;
		}

		return (
			<GameShell onBack={onMenuBack} showBack={status === 'WAITING_FOR_PLAYERS'}>
				<CharacterMatchup gameCode={gameCode} players={players} />
			</GameShell>
		);
	} else {
		switch (menuScreen) {
			case 'MAIN': {
				return (
					<GameShell showBack={false}>
						<MainMenu
							onSelect={(option) => {
								switch (option) {
									case 'HOW_TO_PLAY':
										setMenuScreen('HOW_TO_PLAY');
										break;
									case 'PLAY_ALONE':
										setSelectedGameType('OFFLINE_SINGLE_PLAYER');
										setMenuScreen('CHARACTER_SELECT');
										break;
									case 'PLAY_WITH_FRIEND':
										setMenuScreen('FRIEND');
										break;
									case 'PLAY_WITH_STRANGER':
										setSelectedGameType('ONLINE_PUBLIC');
										setMenuScreen('CHARACTER_SELECT');
										break;
								}
							}}
						/>
					</GameShell>
				);
			}
			case 'FRIEND': {
				return (
					<GameShell onBack={onMenuBack}>
						<FriendMenu
							onSelect={(option) => {
								setSelectedGameType(option);
								setMenuScreen('CHARACTER_SELECT');
							}}
						/>
					</GameShell>
				);
			}
			case 'HOW_TO_PLAY': {
				return (
					<GameShell
						onBack={onMenuBack}
						full={Platform.OS === 'web'}
						scale={Platform.OS !== 'web'}
					>
						<HowToPlay />
					</GameShell>
				);
			}
			case 'CHARACTER_SELECT': {
				return (
					<GameShell onBack={onMenuBack}>
						<CharacterSelect
							selectedPlayers={selectedPlayers.map((player) => player.name)}
							gameType={selectedGameType}
							onSelect={(name) => {
								const playerPayload: PlayerPayload = {
									name,
									playerId: playerIdGenerator(),
									type: 'HUMAN',
									deviceId
								};

								const players = [...selectedPlayers, playerPayload];
								setSelectedPlayers(players);

								if (selectedGameType === 'JOIN_ONLINE_PRIVATE') {
									setMenuScreen('ENTER_CODE');
								} else if (hasEnoughPlayers(selectedGameType, players.length)) {
									dispatch(
										gameActions.create({
											gameType: selectedGameType,
											players
										})
									);
								}
							}}
						/>
					</GameShell>
				);
			}
			case 'ENTER_CODE': {
				return (
					<GameShell onBack={onMenuBack}>
						<EnterCode
							error={status === 'GAME_NOT_FOUND'}
							onErrorDismiss={() => {
								dispatch(actions.reset());
							}}
							onSelect={(gameCode: string) => {
								dispatch(
									gameActions.create({
										gameType: 'JOIN_ONLINE_PRIVATE',
										gameCode,
										players: selectedPlayers
									})
								);
							}}
							searching={status === 'WAITING_FOR_PLAYERS'}
						/>
					</GameShell>
				);
			}
			case 'PROMO': {
				return (
					<GameShell showBack={false}>
						<Promo onMainMenu={onMenuBack} />
					</GameShell>
				);
			}
			default: {
				return <></>;
			}
		}
	}
};

const styles = StyleSheet.create({
	root: {
		flex: 1
	},
	header: {
		flexDirection: 'row',
		justifyContent: 'center'
	},
	rotateWrapper: { paddingHorizontal: 20, alignItems: 'center' },
	rotateImage: { width: 96, height: 96, marginBottom: 30 },
	content: {
		flexDirection: 'row',
		flexGrow: 1,
		flexShrink: 1,
		alignItems: 'center'
	},
	overlay: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 10,
		elevation: 10
	},
	banner: {
		padding: 24,
		borderRadius: 60,
		flexDirection: 'row',
		alignItems: 'center'
	},
	success: { backgroundColor: '#51AE41' },
	fail: {
		backgroundColor: '#EA585C'
	},
	loading: {
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1
	},
	loadingText: { color: '#fff', fontSize: 18, marginTop: 16, marginBottom: 8 },
	loadingQuipText: { color: '#fff', opacity: 0.6, fontSize: 12 },
	bannerIcon: {
		width: 40,
		height: 40,
		marginRight: 16
	},
	avatarWrapper: {
		alignSelf: 'center',
		height: '60%',
		top: '20%',
		zIndex: 10,
		elevation: 10,
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute'
	},
	footer: {
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	floatingFooter: {
		position: 'absolute',
		bottom: 0,
		zIndex: 10,
		right: 0
	},
	backgroundImage: {
		flex: 1,
		justifyContent: Platform.OS === 'web' ? 'flex-start' : 'center',
		alignItems: 'center',
		overflow: 'hidden'
	},
	backgroundImageWebScaledUp: {
		paddingTop: 100
	},
	container: {
		flex: 1,
		justifyContent: 'center',
		width: '100%',
		height: '100%'
	},
	gameContent: {
		paddingHorizontal: 20,
		paddingTop: 20,
		paddingBottom: 10,
		flex: 1
	},
	back: {
		position: 'absolute',
		left: 20,
		top: 30,
		zIndex: 15,
		elevation: 15,
		width: 38,
		height: 22
	},
	cardsLeftFloating: {
		position: 'absolute',
		left: 0,
		top: 5
	},
	inGameButtons: {
		position: 'absolute',
		right: 20,
		top: '50%',
		transform: [{ translateY: -13 }],
		flexDirection: 'row'
	},
	close: {
		width: 23,
		height: 26
	},
	modal: {
		backgroundColor: '#EA585C',
		padding: 16,
		borderRadius: 6,
		alignItems: 'center'
	},
	overlayOpacity: {
		backgroundColor: 'rgba(0,0,0,0.3)'
	},
	modalIcon: { width: 28, height: 32, marginBottom: 16 },
	buttonRow: {
		flexDirection: 'row',
		marginTop: 16
	},
	button: {
		borderWidth: 1,
		borderColor: '#0c0c0c',
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginHorizontal: 8
	},
	buttonFilled: {
		backgroundColor: '#0c0c0c'
	},
	soundIcon: {
		width: 30,
		height: 24,
		marginRight: 16
	}
});

export default Game;
