import React from 'react';
import { View, StyleSheet } from 'react-native';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { SamdanTextLight } from './Text';

export interface CardsRemainingProperties {
	remainingCards: number;
	showTimer: boolean;
}

const CardsRemaining = (props: CardsRemainingProperties) => {
	const { remainingCards, showTimer } = props;

	const timerBarWidth = useAnimatedStyle(() => {
		return showTimer
			? {
					width: withTiming('0%', { duration: 5000 })
			  }
			: {
					width: '100%'
			  };
	}, [showTimer]);

	return (
		<View style={styles.root}>
			{showTimer ? (
				<View style={styles.timer}>
					<Animated.View style={[styles.progress, timerBarWidth]} />
					<SamdanTextLight style={styles.drawCard} size="xxsmall">
						Draw a card
					</SamdanTextLight>
				</View>
			) : (
				<SamdanTextLight size="xxsmall">
					{remainingCards} {remainingCards === 1 ? 'card remains' : 'cards remain'}
				</SamdanTextLight>
			)}
		</View>
	);
};

export default CardsRemaining;

const styles = StyleSheet.create({
	root: {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		borderRadius: 14,
		justifyContent: 'center',
		alignSelf: 'flex-start',
		alignItems: 'center',
		overflow: 'hidden',
		width: 150,
		height: 32
	},
	timer: {
		position: 'relative',
		width: '100%',
		height: '100%',
		justifyContent: 'center'
	},
	progress: {
		backgroundColor: '#EA585C',
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0
	},
	drawCard: {
		textAlign: 'center',
		elevation: 2,
		zIndex: 2
	}
});
