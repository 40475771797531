import React from 'react';
import { View, TouchableOpacity, StyleSheet, Image } from 'react-native';
import { LeagueTextLight, SamdanTextLight } from './Text';
import facetofaceImage from '../assets/images/facetoface.png';
import joingameImage from '../assets/images/joingame.png';
import hostgameImage from '../assets/images/hostgame.png';

type FriendOptions = 'OFFLINE_MULTI_PLAYER' | 'JOIN_ONLINE_PRIVATE' | 'HOST_ONLINE_PRIVATE';

interface FriendMenuProps {
	onSelect(option: FriendOptions): void;
}

export default (props: FriendMenuProps) => {
	const { onSelect } = props;

	return (
		<View style={styles.root}>
			<SamdanTextLight size="xxlarge">Play with a friend</SamdanTextLight>
			<View style={styles.menuRow}>
				<TouchableOpacity
					style={styles.menuItem}
					onPress={() => {
						onSelect('OFFLINE_MULTI_PLAYER');
					}}
				>
					<Image source={facetofaceImage} style={styles.image} />
					<LeagueTextLight size="small" style={styles.typeText}>
						face to face
					</LeagueTextLight>
					<View style={styles.menuButton}>
						<LeagueTextLight size="medium">Start Game</LeagueTextLight>
					</View>
				</TouchableOpacity>
				<TouchableOpacity
					style={styles.menuItem}
					onPress={() => {
						onSelect('JOIN_ONLINE_PRIVATE');
					}}
				>
					<Image source={joingameImage} style={styles.image} />
					<LeagueTextLight size="small" style={styles.typeText}>
						join a game
					</LeagueTextLight>
					<View style={styles.menuButton}>
						<LeagueTextLight size="medium">Enter Code</LeagueTextLight>
					</View>
				</TouchableOpacity>
				<TouchableOpacity
					style={styles.menuItem}
					onPress={() => {
						onSelect('HOST_ONLINE_PRIVATE');
					}}
				>
					<Image source={hostgameImage} style={styles.image} />
					<LeagueTextLight size="small" style={styles.typeText}>
						invite a friend
					</LeagueTextLight>
					<View style={styles.menuButton}>
						<LeagueTextLight size="medium">Host Game</LeagueTextLight>
					</View>
				</TouchableOpacity>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	root: {
		flex: 1,
		width: '100%',
		alignItems: 'center'
	},
	back: {
		position: 'absolute',
		left: 0
	},
	menuRow: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		width: '80%',
		flex: 1
	},
	menuItem: {
		padding: 10,
		marginHorizontal: 7,
		maxWidth: '30%',
		alignItems: 'center'
	},
	menuButton: {
		paddingVertical: 6,
		paddingHorizontal: 12,
		backgroundColor: '#000',
		marginTop: 16,
		borderRadius: 8
	},
	image: {
		width: 132,
		height: 132,
		marginBottom: 16
	},
	typeText: {
		opacity: 0.8
	}
});
