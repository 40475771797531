import React, { useEffect, useRef, useState } from 'react';
import { Animated, Image, View, StyleSheet } from 'react-native';
import Lottie from './Lottie';
import cardBackImage from '../assets/images/cards/back/Cards-Back.png';

interface CardProperties {
	rotation: number;
	onAnimationEnd(): void;
	image: any;
	burnt?: boolean;
	mayhem?: boolean;
}

const baseCardWidth = 150;

const CardMayhemAnimation = () => {
	const [animationEnded, setAnimationEnded] = useState(false);
	const width = baseCardWidth;
	const height = width * 1.4;
	const progress = useRef(new Animated.Value(0)).current;
	const scale = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.sequence([
			Animated.timing(scale, {
				toValue: 1,
				delay: 400,
				duration: 1,
				useNativeDriver: true
			}),
			Animated.timing(progress, {
				toValue: 1,
				duration: 500,
				useNativeDriver: true
			}),
			Animated.timing(progress, {
				toValue: 0,
				duration: 500,
				useNativeDriver: true
			}),
			Animated.timing(scale, {
				toValue: 0,
				duration: 50,
				useNativeDriver: true
			})
		]).start(() => {
			setAnimationEnded(true);
		});
	}, []);

	if (animationEnded) {
		return null;
	}

	return (
		<Animated.View
			style={{
				width,
				height,
				position: 'absolute',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				transform: [{ scale }]
			}}
		>
			<Lottie
				autoPlay
				progress={progress}
				loop={false}
				style={{
					width: 320,
					height: 370
				}}
				source={require('../assets/lottie/explosion.json')}
			/>
		</Animated.View>
	);
};

export default ({ rotation, onAnimationEnd, image, burnt, mayhem }: CardProperties) => {
	const width = baseCardWidth;

	const drawCardFlip = useRef(new Animated.Value(0));
	const drawCardFlipOpacity = useRef(new Animated.Value(0));
	const drawCardOpacity = useRef(new Animated.Value(0));
	const drawCardAnimationLength = 300;

	const height = width * 1.4;
	const burntOpacity = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		if (burnt) {
			Animated.timing(burntOpacity, {
				toValue: 0.7,
				delay: 400,
				duration: 500,
				useNativeDriver: true
			}).start();
		}
	}, [burnt]);

	useEffect(() => {
		const animations = [
			Animated.timing(drawCardOpacity.current, {
				toValue: 1,
				duration: 50,
				useNativeDriver: true
			}),
			Animated.parallel([
				Animated.timing(drawCardFlip.current, {
					toValue: 1,
					duration: drawCardAnimationLength,
					useNativeDriver: true
				}),
				Animated.timing(drawCardFlipOpacity.current, {
					toValue: 1,
					delay: drawCardAnimationLength / 2,
					duration: 0,
					useNativeDriver: true
				})
			])
		];

		Animated.sequence(animations).start(() => onAnimationEnd());
	}, []);

	const rotationDegrees = drawCardFlip.current.interpolate({
		inputRange: [0, 1],
		outputRange: ['-180deg', '0deg']
	});

	const backOpacity = drawCardFlipOpacity.current.interpolate({
		inputRange: [0, 1],
		outputRange: [1, 0]
	});

	return (
		<Animated.View
			style={{
				position: 'relative',
				width: width,
				height: width * 1.4,
				opacity: drawCardOpacity.current,
				transform: [{ perspective: 800 }, { rotateY: rotationDegrees }]
			}}
		>
			<Animated.View
				style={{
					position: 'absolute',
					opacity: drawCardFlipOpacity.current
				}}
			>
				<View
					style={{
						transform: [{ rotate: `${rotation}deg` }]
					}}
				>
					{mayhem && <CardMayhemAnimation />}
					<View style={[styles.card, styles.shadow]}>
						{burnt && (
							<Animated.View style={[styles.burnt, { opacity: burntOpacity }]} />
						)}
						<Image style={{ width, height }} source={image} />
					</View>
				</View>
			</Animated.View>
			<Animated.View
				style={{
					position: 'absolute',
					opacity: backOpacity
				}}
			>
				<Image
					style={{
						width: width,
						height: width * 1.4,
						transform: [{ rotateY: '180deg' }],
						borderRadius: 11
					}}
					source={cardBackImage}
				/>
			</Animated.View>
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	card: {
		borderRadius: 11,
		overflow: 'hidden',
		// adding a background color here will fix the RN warnings about not being able to calculate
		// a shadow efficiently, but it gives the cards jagged edges when they're tilted.
	},
	shadow: {
		shadowColor: '#000',
		shadowOpacity: 0.3,
		shadowRadius: 4
	},
	burnt: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: 1,
		elevation: 1,
		backgroundColor: '#000'
	}
});
