import cards from './cards';
import { Player, GameState, GameType } from './gameSlice';

function getSeed(s: number) {
	return function () {
		s = parseFloat(Math.sin(s).toFixed(5)) * 10000;
		return s - Math.floor(s);
	};
}

function shuffle<T>(array: T[], randomFunc: () => number) {
	let currentIndex = array.length,
		randomIndex;
	while (currentIndex != 0) {
		randomIndex = Math.floor(randomFunc() * currentIndex);
		currentIndex--;
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}
	return array;
}

export function getFirstPlayerId(players: Player[], seed: number) {
	const sortedIds = players.map((player) => player.playerId).sort();
	const [firstPlayerId] = shuffle(sortedIds, getSeed(seed));
	return firstPlayerId;
}

export function getDeck(seed: number) {
	return shuffle([...cards], getSeed(seed));
}

function getCard(seed: number, index: number) {
	return getDeck(seed)[index];
}

export function getCurrentPlayer(game: GameState): Player {
	const { players = [], currentPlayer } = game;
	return players.find((player) => player.playerId === currentPlayer)!;
}

export function getNextPlayer(game: GameState) {
	const { players, currentPlayer } = game;
	const playerIndex = players.findIndex((player) => player.playerId === currentPlayer);
	const nextPlayerIndex = (playerIndex + players.length + 1) % players.length;
	return players[nextPlayerIndex];
}

export function getOtherPlayers(game: GameState) {
	return game.players.filter((player) => player.playerId !== game.currentPlayer);
}

export function hasMayhemCardInHand(game: GameState) {
	const { handStartCard, currentCard } = game;
	for (let i = handStartCard; i < currentCard; i++) {
		const card = getCard(game.seed, i);
		if (card?.type === 'mayhem') {
			return true;
		}
	}
	return false;
}

export function hasOneCardInHand(game: GameState) {
	const { currentCard, handStartCard } = game;
	return currentCard - handStartCard === 1;
}

export function isAI(game: GameState) {
	return getCurrentPlayer(game)?.type === 'AI';
}

export function getScore(game: GameState) {
	const currentPlayer = getCurrentPlayer(game);
	return (currentPlayer && currentPlayer.score) || 0;
}

export async function pause(length: number) {
	return new Promise<void>((res) => setTimeout(() => res(), length));
}

export function hasEnoughPlayers(players: Player[]) {
	return players.length > 1;
}

export function getHandScore(game: GameState) {
	const { currentCard, handStartCard } = game;

	const deck = getDeck(game.seed);
	const hand = deck.slice(handStartCard, currentCard);

	if (hand.filter((card) => card.type === 'mayhem').length > 1) {
		return -1;
	}

	return hand.reduce((score, card, index) => {
		const nextCard = hand[index + 1];
		if (nextCard?.type === 'mayhem') {
			return score;
		}
		return score + card.value;
	}, 0);
}

export function isOnlineType(type?: GameType) {
	return (
		type === 'HOST_ONLINE_PRIVATE' || type === 'JOIN_ONLINE_PRIVATE' || type === 'ONLINE_PUBLIC'
	);
}

export function isOfflineType(type?: GameType) {
	return type === 'OFFLINE_SINGLE_PLAYER' || type === 'OFFLINE_MULTI_PLAYER';
}

export function isEmptyDeck(game: GameState) {
	return game.currentCard === cards.length;
}

export function getWinner(game: GameState) {
	return game.players.find((player) => {
		return player.score > 19;
	});
}
