import React, { useEffect, useRef } from 'react';
import { StyleSheet, Animated, Image, View, Easing } from 'react-native';
import { getDeck } from './util';
import Card from './Card';
import { usePageVisibility } from './usePageVisibility';
import mayhemImage from '../assets/images/mayhem.png';

interface HandProperties {
	seed: number;
	start: number;
	end: number;
	animateOut: boolean;
	onAnimatedOut(): void;
	onMayhemCard(): void;
	onBust(): void;
}

const CardDraw = ({
	startOffset,
	children
}: {
	startOffset: number;
	children: React.ReactElement;
}) => {
	const drawCardTransformX = useRef(new Animated.Value(startOffset));
	const drawCardAnimationLength = 300;

	useEffect(() => {
		Animated.timing(drawCardTransformX.current, {
			delay: drawCardAnimationLength / 2,
			toValue: 0,
			easing: Easing.ease,
			duration: drawCardAnimationLength,
			useNativeDriver: true
		}).start();
	}, []);

	return (
		<Animated.View
			style={[
				{
					transform: [{ translateX: drawCardTransformX.current }]
				}
			]}
		>
			{children}
		</Animated.View>
	);
};

const rotationAngles = [-2, -1, 0, 1, 2];

export default ({
	seed,
	start,
	end,
	animateOut,
	onAnimatedOut,
	onBust,
	onMayhemCard
}: HandProperties) => {
	const deck = getDeck(seed);
	const hand = deck.slice(start, end);

	const endTurnOpacity = useRef(new Animated.Value(1));
	const endTurnScale = useRef(new Animated.Value(1));
	const mayhemScale = useRef(new Animated.Value(0));
	const isBust = hand.filter((card) => card.type == 'mayhem').length > 1;

	const [visible] = usePageVisibility();

	useEffect(() => {
		if (animateOut) {
			if (visible && hand.length) {
				endTurnOpacity.current.setValue(1);
				endTurnScale.current.setValue(1);
				const endTurnAnimations = [
					Animated.timing(endTurnOpacity.current, {
						toValue: 0,
						duration: 600,
						delay: 350,
						useNativeDriver: true
					}),
					Animated.timing(endTurnScale.current, {
						toValue: 0.75,
						duration: 600,
						delay: 350,
						useNativeDriver: true
					})
				];

				if (isBust) {
					mayhemScale.current.setValue(0);
					const mayhemScaleAnimation = Animated.sequence([
						Animated.delay(800),
						Animated.spring(mayhemScale.current, {
							toValue: 1.2,
							stiffness: 150,
							useNativeDriver: true
						})
					]);

					Animated.sequence([
						mayhemScaleAnimation,
						Animated.parallel(endTurnAnimations)
					]).start(onAnimatedOut);
				} else {
					Animated.parallel(endTurnAnimations).start(onAnimatedOut);
				}
			} else {
				onAnimatedOut();
			}
		}
	}, [isBust, animateOut, hand.length]);

	return (
		<Animated.View
			key="root"
			style={[
				styles.root,
				{
					opacity: endTurnOpacity.current,
					transform: [
						{
							scale: endTurnScale.current
						}
					]
				}
			]}
		>
			{hand.map((card, index) => {
				const nextCard = hand[index + 1];
				const isMayhemCard = card.type === 'mayhem';
				const burnt = !isMayhemCard && nextCard?.type === 'mayhem';
				const position = index + 1;
				const rotation =
					rotationAngles[(position + rotationAngles.length) % rotationAngles.length];
				return (
					<View
						style={{ marginLeft: position === 1 ? 20 : -120 }}
						key={`animated-card-${index}`}
					>
						<CardDraw startOffset={-140 - 30 * position}>
							<Card
								rotation={rotation}
								onAnimationEnd={() => {
									if (isBust) {
										onBust();
									} else if (isMayhemCard) {
										onMayhemCard();
									}
								}}
								burnt={burnt}
								image={card.image}
								mayhem={isMayhemCard}
							/>
						</CardDraw>
					</View>
				);
			})}
			{isBust && (
				<View style={styles.overlay}>
					<Animated.View
						style={[styles.mayhem, { transform: [{ scale: mayhemScale.current }] }]}
					>
						<Image style={{ width: 328.05, height: 148.3 }} source={mayhemImage} />
					</Animated.View>
				</View>
			)}
		</Animated.View>
	);
};

const styles = StyleSheet.create({
	root: {
		flexDirection: 'row',
		flex: 1
	},
	overlay: {
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 10,
		elevation: 10,
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute'
	},
	mayhem: {
		left: -77,
		transform: [{ scale: 0 }]
	}
});
