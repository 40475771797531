import { FunctionComponent } from 'react';

import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

const fontSizes = {
	xxsmall: 16,
	xsmall: 18,
	small: 20,
	medium: 24,
	large: 30,
	xlarge: 36,
	xxlarge: 42
};

type FontSize = keyof typeof fontSizes;

export interface TextProperties extends TextProps {
	size?: FontSize;
}

function getFontSize(size: FontSize = 'medium') {
	return fontSizes[size];
}

const baseLeagueStyles: StyleProp<TextStyle> = {
	textTransform: 'uppercase',
	fontFamily: 'league'
};

const baseSamdanStyles: StyleProp<TextStyle> = {
	textTransform: 'uppercase',
	fontFamily: 'samdan'
};

export const LeagueText: FunctionComponent<TextProperties> = (props) => (
	<Text
		{...props}
		style={[props.style, { ...baseLeagueStyles, fontSize: getFontSize(props.size) }]}
	/>
);

export const SamdanText: FunctionComponent<TextProperties> = (props) => (
	<Text
		{...props}
		style={[props.style, { ...baseSamdanStyles, fontSize: getFontSize(props.size) }]}
	/>
);

export const LeagueTextLight: FunctionComponent<TextProperties> = (props) => (
	<Text
		{...props}
		style={[
			props.style,
			{ ...baseLeagueStyles, color: '#fff', fontSize: getFontSize(props.size) }
		]}
	/>
);

export const SamdanTextLight: FunctionComponent<TextProperties> = (props) => (
	<Text
		{...props}
		style={[
			props.style,
			{ ...baseSamdanStyles, color: '#fff', fontSize: getFontSize(props.size) }
		]}
	/>
);
