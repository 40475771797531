import React, { useEffect, useState } from 'react';
import { View, StyleSheet, ActivityIndicator, Image, TouchableOpacity } from 'react-native';
import { LeagueTextLight, SamdanTextLight } from './Text';
import { Player } from './gameSlice';
import Avatar from './Avatar';
import { setStringAsync } from 'expo-clipboard';
import Animated, {
	useSharedValue,
	useAnimatedStyle,
	withTiming,
	runOnJS,
	withSpring,
	withSequence,
	withDelay
} from 'react-native-reanimated';
import copyIcon from '../assets/images/copy.png';

interface CharacterMatchupProps {
	gameCode?: string;
	players: Player[];
}

export default (props: CharacterMatchupProps) => {
	const { gameCode, players } = props;
	const [playerOne, playerTwo] = players;
	const [copied, setCopied] = useState(false);

	const copiedScale = useSharedValue(0);

	useEffect(() => {
		if (copied) {
			copiedScale.value = withSequence(
				withSpring(1),
				withDelay(
					500,
					withTiming(0, { duration: 200 }, () => {
						runOnJS(setCopied)(false);
					})
				)
			);
		} else {
			copiedScale.value = 0;
		}
	}, [copied]);

	const animatedCopiedStyle = useAnimatedStyle(() => ({
		transform: [{ scale: copiedScale.value }]
	}));

	return (
		<View style={styles.root}>
			{gameCode && (
				<TouchableOpacity
					onPress={() => {
						setStringAsync(gameCode);
						setCopied(true);
					}}
					style={styles.roomCode}
				>
					<LeagueTextLight>Room Code: {gameCode}</LeagueTextLight>
					<Image source={copyIcon} style={{ width: 18.5, height: 18.5, marginLeft: 8 }} />
				</TouchableOpacity>
			)}
			{copied && (
				<Animated.View style={[styles.copied, animatedCopiedStyle]}>
					<LeagueTextLight>Code Copied</LeagueTextLight>
				</Animated.View>
			)}
			{playerOne && (
				<View style={styles.matchRow}>
					<View style={styles.avatarWrapper}>
						<Avatar name={playerOne.name} size="large" />
						<LeagueTextLight>{playerOne.name}</LeagueTextLight>
					</View>
					<View style={styles.vs}>
						<SamdanTextLight size="large">VS</SamdanTextLight>
					</View>
					<View style={styles.avatarWrapper}>
						{playerTwo ? (
							<>
								<Avatar name={playerTwo.name} size="large" />
								<LeagueTextLight size="medium">{playerTwo.name}</LeagueTextLight>
							</>
						) : (
							<>
								<LeagueTextLight size="small">Waiting for opponent</LeagueTextLight>
								<ActivityIndicator
									size="large"
									style={{ marginTop: 10 }}
									color="#fff"
								/>
							</>
						)}
					</View>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},
	copied: {
		paddingHorizontal: 24,
		paddingVertical: 10,
		backgroundColor: '#EA585C',
		borderRadius: 40,
		position: 'absolute',
		top: 0,
		elevation: 10
	},
	matchRow: {
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: 32
	},
	avatar: {
		marginBottom: 10,
		width: 152,
		height: 152,
		borderRadius: 100,
		borderColor: '#000',
		borderWidth: 5,
		backgroundColor: '#BCE8EC'
	},
	avatarWrapper: {
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	vs: {
		marginHorizontal: 84
	},
	roomCode: {
		paddingHorizontal: 24,
		paddingVertical: 10,
		backgroundColor: '#000',
		borderRadius: 40,
		flexDirection: 'row',
		alignItems: 'center'
	}
});
