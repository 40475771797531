import React from 'react';
import { Pressable, View, Image, StyleSheet, Dimensions } from 'react-native';
import cards from './cards';
import fullDeckImage from '../assets/images/cards/fullDeck.png';
import halfDeckImage from '../assets/images/cards/halfDeck.png';
import lowDeckImage from '../assets/images/cards/lowDeck.png';

interface DeckProps {
	onPress(): void;
	disabled: boolean;
	currentCard: number;
}

const deckWidth = 155;
const defaultHeight = deckWidth * 1.4;

export default (props: DeckProps) => {
	const { onPress, disabled, currentCard } = props;
	const remainingCards = cards.length - currentCard;
	const cardHeight = Math.min(Math.floor(Dimensions.get('window').height / 2), defaultHeight);
	const cardStyle = {
		height: cardHeight,
		width: cardHeight / 1.4,
		borderRadius: 11
	} as const;

	const deckImage =
		remainingCards > 30 ? fullDeckImage : remainingCards > 5 ? halfDeckImage : lowDeckImage;

	return (
		<Pressable disabled={disabled} onPress={onPress} style={styles.deck}>
			{remainingCards ? (
				<Image style={cardStyle} source={deckImage} />
			) : (
				<View style={[cardStyle, styles.emptyDeck]}></View>
			)}
		</Pressable>
	);
};

const styles = StyleSheet.create({
	emptyDeck: {
		borderWidth: 2,
		borderStyle: 'dashed',
		borderColor: 'rgba(255, 255, 255, 0.25)',
		backgroundColor: 'rgba(217, 217, 217, 0.05)'
	},
	deck: {
		width: deckWidth,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center'
	}
});
