import React, { useState } from 'react';
import {
	View,
	TextInput,
	TouchableOpacity,
	StyleSheet,
	Image,
	ActivityIndicator,
	Modal
} from 'react-native';
import { LeagueTextLight, SamdanTextLight } from './Text';
import skullIcon from '../assets/images/skull.png';

interface EnterCodeProps {
	onSelect(joinCode: string): void;
	error?: boolean;
	onErrorDismiss(): void;
	searching: boolean;
}

export default (props: EnterCodeProps) => {
	const { onSelect, error, searching, onErrorDismiss } = props;
	const [joinCode, setJoinCode] = useState('');
	const buttonDisabled = joinCode.length !== 6 || searching;

	return (
		<View style={styles.root}>
			<SamdanTextLight size="xxlarge" style={{ marginBottom: 20 }}>
				Enter room code
			</SamdanTextLight>
			<View style={styles.inputWrapper}>
				<TextInput
					style={styles.input}
					placeholder="Enter Code"
					value={joinCode}
					autoFocus
					autoCapitalize="characters"
					onChangeText={(joinCode) => setJoinCode(joinCode)}
				></TextInput>
				<TouchableOpacity
					style={[styles.button, buttonDisabled && styles.disabled]}
					disabled={buttonDisabled}
					onPress={() => {
						onSelect(joinCode);
					}}
				>
					<LeagueTextLight size="large">
						{searching ? <ActivityIndicator size="small" /> : 'Join room'}
					</LeagueTextLight>
				</TouchableOpacity>
			</View>
			<Modal transparent visible={error} supportedOrientations={['landscape']}>
				<View style={[styles.overlay, styles.overlayOpacity]}>
					<View style={styles.error}>
						<Image source={skullIcon} style={styles.icon} />
						<LeagueTextLight>The room code you’ve entered is invalid</LeagueTextLight>
						<TouchableOpacity onPress={onErrorDismiss} style={styles.modalButton}>
							<LeagueTextLight size="small">Try Again</LeagueTextLight>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
		width: '100%',
		alignItems: 'center'
	},

	error: {
		backgroundColor: '#EA585C',
		padding: 16,
		borderRadius: 6,
		alignItems: 'center',
		marginTop: 40
	},
	icon: { width: 28, height: 32, marginBottom: 16 },
	inputWrapper: {
		marginTop: 30,
		flexDirection: 'row'
	},
	overlay: {
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 10,
		elevation: 10
	},
	overlayOpacity: {
		backgroundColor: 'rgba(0,0,0,0.3)'
	},
	input: {
		width: 225,
		height: 50,
		padding: 8,
		backgroundColor: '#fff',
		borderRadius: 6,
		textTransform: 'uppercase',
		fontFamily: 'league',
		fontSize: 24
	},
	button: {
		marginLeft: 12,
		paddingHorizontal: 26,
		paddingVertical: 8,
		borderRadius: 6,
		backgroundColor: '#000',
		minWidth: 140,
		alignItems: 'center'
	},
	disabled: {
		opacity: 0.5
	},
	modalButton: {
		marginTop: 16,
		borderWidth: 1,
		borderColor: '#0c0c0c',
		paddingHorizontal: 16,
		paddingVertical: 8,
		marginHorizontal: 8,
		backgroundColor: '#0c0c0c'
	}
});
