import React from 'react';
import { TouchableOpacity, View, StyleSheet, Image, Dimensions } from 'react-native';
import { LeagueTextLight } from './Text';
import logoImage from '../assets/images/logo.png';

type MainMenuOptions = 'PLAY_ALONE' | 'PLAY_WITH_FRIEND' | 'PLAY_WITH_STRANGER' | 'HOW_TO_PLAY';

interface MainMenuProps {
	onSelect(option: MainMenuOptions): void;
}

export default (props: MainMenuProps) => {
	return (
		<View style={styles.root}>
			<View style={styles.logoWrapper}>
				<Image
					style={{ width: '100%', height: '100%' }}
					resizeMode="contain"
					source={logoImage}
				/>
			</View>
			<View style={styles.menu}>
				<TouchableOpacity onPress={() => props.onSelect('PLAY_ALONE')}>
					<LeagueTextLight size="xlarge">Play Alone</LeagueTextLight>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.onSelect('PLAY_WITH_FRIEND')}>
					<LeagueTextLight size="xlarge">Play with a friend</LeagueTextLight>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.onSelect('PLAY_WITH_STRANGER')}>
					<LeagueTextLight size="xlarge">Play with a stranger</LeagueTextLight>
				</TouchableOpacity>
				<TouchableOpacity onPress={() => props.onSelect('HOW_TO_PLAY')}>
					<LeagueTextLight size="medium">How to play</LeagueTextLight>
				</TouchableOpacity>
			</View>
		</View>
	);
};

const devHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
	root: {
		alignSelf: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		flexGrow: 1,
		width: '100%',
		maxWidth: 1024,
		flexWrap: 'wrap'
	},
	menu: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		paddingHorizontal: 40,
		minWidth: 250,
		gap: devHeight > 400 ? 18 : 10
	},
	logoWrapper: {
		flex: 1,
		paddingHorizontal: 40,
		minWidth: 250
	}
});
