import React from 'react';
import { View, StyleSheet, Pressable, useWindowDimensions } from 'react-native';
import Animated, {
	withTiming,
	useAnimatedStyle,
	withSpring,
	withDelay
} from 'react-native-reanimated';
import { SamdanText } from './Text';

export interface StickButtonProperties {
	handScore: number;
	onPress: () => void;
	disabled?: boolean;
}

const config = {
	duration: 150
};

const StickButton = (props: StickButtonProperties) => {
	const { handScore = 0, onPress, disabled = false } = props;
	const { height } = useWindowDimensions();
	const isMobileWebSized = height < 350;

	const expandTakePointsStyle = useAnimatedStyle(() => {
		return disabled
			? {
					opacity: withTiming(0, config),
					width: withDelay(50, withTiming(0, config))
			  }
			: {
					width: withDelay(50, withSpring(100, { mass: 0.5 })),
					opacity: withDelay(150, withTiming(1, config))
			  };
	});

	return (
		<Pressable disabled={disabled} onPress={onPress}>
			<View style={[styles.root, isMobileWebSized && styles.rootCondensed]}>
				<View style={[styles.score, isMobileWebSized && styles.scoreCondensed]}>
					<SamdanText size="medium">+{Math.max(handScore, 0)}</SamdanText>
				</View>

				<Animated.View key="takepoints" style={[styles.takePoints, expandTakePointsStyle]}>
					<SamdanText size="xsmall">Take points</SamdanText>
				</Animated.View>
			</View>
		</Pressable>
	);
};

export default StickButton;

const styles = StyleSheet.create({
	root: {
		backgroundColor: '#fff',
		borderRadius: 100,
		padding: 1,
		flexDirection: 'row',
		alignItems: 'center',
		height: 42,
		overflow: 'hidden'
	},
	rootCondensed: {
		height: 36
	},
	score: {
		backgroundColor: '#9CDDE4',
		width: 42,
		height: 42,
		borderRadius: 100,
		alignItems: 'center',
		justifyContent: 'center'
	},
	scoreCondensed: {
		width: 36,
		height: 36
	},
	takePoints: {
		alignItems: 'center',
		justifyContent: 'center',
		width: 0,
		opacity: 0
	}
});
