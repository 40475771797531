import 'react-native-url-polyfill/auto';
import React from 'react';
import { Provider } from 'react-redux';
import Game from './src/Game';
import { store } from './src/store';

// FIXME need reanimated update, see
// https://github.com/software-mansion/react-native-reanimated/issues/3355
if (typeof process !== 'undefined' && process?.browser) {
	window._frameTimestamp = null;
}

export default function App() {
	return (
		<Provider store={store}>
			<Game />
		</Provider>
	);
}
