const cards = [
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem01.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem02.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem03.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem04.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem05.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem06.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem07.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem08.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem09.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem10.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem11.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem12.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem13.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem14.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem15.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem16.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem17.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem18.png') },
	{ type: 'mayhem', value: 0, image: require('../assets/images/cards/front/Cards-Mayhem19.png') },
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum01.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum02.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum03.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum04.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum05.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum06.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum07.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum08.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum09.png')
	},
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum10.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum11.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum12.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum13.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum14.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum15.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum16.png')
	},
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum17.png')
	},
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum18.png')
	},
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum19.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum20.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum21.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum22.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum23.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum24.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum25.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum26.png')
	},
	{
		type: 'momentum',
		value: 2,
		image: require('../assets/images/cards/front/Cards-Momentum27.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum28.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum29.png')
	},
	{
		type: 'momentum',
		value: 3,
		image: require('../assets/images/cards/front/Cards-Momentum30.png')
	},
	{
		type: 'momentum',
		value: 1,
		image: require('../assets/images/cards/front/Cards-Momentum31.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum32.png')
	},
	{
		type: 'momentum',
		value: 4,
		image: require('../assets/images/cards/front/Cards-Momentum33.png')
	}
];

export default cards;
