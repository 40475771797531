import React from 'react';
import { View, TouchableOpacity, StyleSheet, useWindowDimensions, Platform } from 'react-native';
import Avatar from './Avatar';
import { GameType, PlayerName, playerNames } from './gameSlice';
import { LeagueTextLight, SamdanTextLight } from './Text';

interface CharacterSelectProps {
	onSelect(name: PlayerName): void;
	selectedPlayers?: string[];
	gameType?: GameType;
}

export default (props: CharacterSelectProps) => {
	const { onSelect, selectedPlayers = [], gameType } = props;
	const { height } = useWindowDimensions();
	const isMobileWebSized = height < 350;

	const playerNumber =
		gameType === 'OFFLINE_MULTI_PLAYER' ? selectedPlayers.length + 1 : undefined;

	return (
		<View style={styles.root}>
			<SamdanTextLight size={isMobileWebSized ? 'large' : 'xxlarge'}>
				Select your character
			</SamdanTextLight>
			{playerNumber && (
				<LeagueTextLight
					size={isMobileWebSized ? 'medium' : 'large'}
					style={!isMobileWebSized && styles.playerNumber}
				>
					Player {playerNumber}
				</LeagueTextLight>
			)}

			<View style={[styles.avatarList, Platform.OS !== 'web' && styles.avatarListFlex]}>
				{playerNames.map((playerName) => {
					const playerNumber = selectedPlayers?.indexOf(playerName);
					const disabled = playerNumber !== -1;
					return (
						<TouchableOpacity
							disabled={disabled}
							style={styles.avatarWrapper}
							key={`avatar-choice-${playerName}`}
							onPress={() => {
								onSelect(playerName);
							}}
						>
							<Avatar
								name={playerName}
								playerNumber={playerNumber + 1}
								disabled={disabled}
							/>
							<LeagueTextLight size="small">{playerName}</LeagueTextLight>
						</TouchableOpacity>
					);
				})}
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	root: {
		flex: 1,
		width: '100%',
		alignItems: 'center'
	},
	back: {
		position: 'absolute',
		left: 0
	},
	playerNumber: {
		marginBottom: 14
	},
	avatarList: {
		flexWrap: 'wrap',
		flexDirection: 'row',
		maxWidth: 512,
		marginTop: 20,
		justifyContent: 'center',
		alignContent: 'center',
		flex: 1
	},
	avatarListFlex: {
		flex: 1,
		marginTop: 0
	},
	avatarWrapper: {
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: 14,
		marginHorizontal: 20,
		minWidth: 80
	}
});
