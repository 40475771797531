import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import Animated, {
	withTiming,
	useAnimatedStyle,
	Easing,
	interpolateColor,
	useDerivedValue
} from 'react-native-reanimated';
import Avatar from './Avatar';
import { PlayerName } from './gameSlice';
import { SamdanText, LeagueTextLight } from './Text';

export interface PlayerScoreProperties {
	totalScore: number;
	handScore: number;
	side: 'leading' | 'trailing';
	active: boolean;
	name: PlayerName;
}

const shades = ['#000000', '#35b4c1'];

const progessBarConfig = {
	duration: 500,
	easing: Easing.bezier(0.5, 0.01, 0, 1)
};

const PlayerScore = (props: PlayerScoreProperties) => {
	const { totalScore, handScore, side, active, name } = props;

	const { height } = useWindowDimensions();
	const isMobileWebSized = height < 350;

	const totalWidth = Math.ceil((totalScore / 20) * 100);
	const handWidth = active ? Math.ceil((handScore / 20) * 100) : 0;
	const isLeading = side === 'leading';

	const totalBarStyle = useAnimatedStyle(() => {
		return {
			width: withTiming(`${totalWidth}%`, progessBarConfig)
		};
	});

	const handBarStyle = useAnimatedStyle(() => {
		return {
			width: withTiming(`${totalWidth + handWidth}%`, progessBarConfig)
		};
	});

	const activeProgress = useDerivedValue(() => {
		return withTiming(active ? 1 : 0, { duration: 350 });
	});

	const activeBackgroundStyle = useAnimatedStyle(() => {
		const backgroundColor = interpolateColor(activeProgress.value, [0, 1], shades);
		return {
			backgroundColor
		};
	});

	return (
		<View style={styles.root}>
			{!isLeading && (
				<>
					<Animated.View
						style={[
							styles.avatarWrapper,
							activeBackgroundStyle,
							{
								left: isMobileWebSized ? 5 : 10
							}
						]}
					>
						<Avatar name={name} size={isMobileWebSized ? 'xsmall' : 'small'} />
					</Animated.View>
					<View
						style={[
							styles.score,
							isMobileWebSized && styles.scoreCondensed,
							{ left: isMobileWebSized ? 40 : 50 }
						]}
					>
						<View style={styles.scoreInner}>
							<SamdanText size={isMobileWebSized ? 'xsmall' : 'small'}>
								{totalScore}
							</SamdanText>
						</View>
					</View>
				</>
			)}
			<Animated.View
				style={[
					styles.nameProgressWrapper,
					activeBackgroundStyle,
					isLeading
						? { paddingRight: 60, marginRight: isMobileWebSized ? 20 : 40 }
						: { paddingLeft: 60, marginLeft: isMobileWebSized ? 20 : 40 }
				]}
			>
				<LeagueTextLight
					size={isMobileWebSized ? 'xxsmall' : 'xsmall'}
					style={{ textAlign: isLeading ? 'right' : 'left' }}
				>
					{name}
				</LeagueTextLight>
				<View
					style={[
						styles.progressWrapper,
						isMobileWebSized && styles.progressWrapperCondensed,
						active && styles.activeProgressWrapper,
						{
							alignItems: isLeading ? 'flex-end' : 'flex-start'
						}
					]}
				>
					<Animated.View style={[styles.progressTotal, totalBarStyle]}></Animated.View>
					{active && (
						<Animated.View style={[styles.progressHand, handBarStyle]}></Animated.View>
					)}
				</View>
			</Animated.View>
			{isLeading && (
				<>
					<View
						style={[
							styles.score,
							isMobileWebSized && styles.scoreCondensed,
							{ right: isMobileWebSized ? 40 : 50 }
						]}
					>
						<View style={styles.scoreInner}>
							<SamdanText size={isMobileWebSized ? 'xsmall' : 'small'}>
								{totalScore}
							</SamdanText>
						</View>
					</View>
					<Animated.View
						style={[
							styles.avatarWrapper,
							activeBackgroundStyle,
							{ right: isMobileWebSized ? 5 : 10 }
						]}
					>
						<Avatar name={name} size={isMobileWebSized ? 'xsmall' : 'small'} />
					</Animated.View>
				</>
			)}
		</View>
	);
};

export default PlayerScore;

const styles = StyleSheet.create({
	root: {
		alignItems: 'center',
		flexDirection: 'row'
	},
	nameProgressWrapper: {
		marginHorizontal: 8,
		borderRadius: 12,
		backgroundColor: '#000',
		padding: 5
	},
	score: {
		position: 'absolute',
		borderRadius: 50,
		backgroundColor: '#000',
		alignSelf: 'center',
		width: 45,
		height: 35,
		zIndex: 3,
		elevation: 3,
		padding: 2
	},
	scoreInner: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 50,
		backgroundColor: '#fff'
	},
	scoreCondensed: {
		width: 36,
		height: 24
	},
	avatarWrapper: {
		position: 'absolute',
		borderRadius: 100,
		padding: 5,
		backgroundColor: '#000',
		zIndex: 1,
		elevation: 1
	},
	progressWrapper: {
		height: 17,
		width: 137,
		borderRadius: 6,
		backgroundColor: '#000',
		borderWidth: 1,
		borderColor: '#404040',
		overflow: 'hidden'
	},
	progressWrapperCondensed: {
		width: 90,
		height: 13,
		borderRadius: 4
	},
	activeProgressWrapper: {
		borderColor: '#000'
	},
	progressTotal: {
		backgroundColor: '#5CC7D2',
		height: '100%',
		borderRadius: 4,
		zIndex: 2,
		elevation: 2,
		position: 'absolute'
	},
	progressHand: {
		backgroundColor: '#2E6269',
		height: '100%',
		borderRadius: 4,
		zIndex: 1,
		elevation: 1,
		position: 'absolute'
	},
	inactiveWrapper: {
		position: 'absolute',
		zIndex: 2,
		elevation: 2,
		padding: 5,
		top: 0,
		left: 0
	}
});
