import React from 'react';
import { View, StyleSheet, ScrollView, Text, Image } from 'react-native';
import { LeagueTextLight, SamdanTextLight } from './Text';
import Constants from 'expo-constants';

const githubSha = Constants.expoConfig?.extra?.GITHUB_SHA;

export default () => {
	return (
		<View style={styles.root}>
			<SamdanTextLight style={{ alignSelf: 'center', marginBottom: 30 }} size="xxlarge">
				How to play
			</SamdanTextLight>
			<ScrollView>
				<LeagueTextLight size="xlarge" style={styles.heading}>
					Taking your turn
				</LeagueTextLight>
				<Text style={styles.instruction}>
					Draw a card from the deck. The card will either be a Momentum or Mayhem card. If
					the card is a Momentum card, the amount shown on the card will be added to your
					potential points.
				</Text>
				<Text style={styles.instruction}>
					You may draw another card or end your turn by pressing the Take Points button.
				</Text>
				<Text style={styles.instruction}>
					The first team to complete their milestone by gaining 20 points is the winner.
				</Text>
				<LeagueTextLight size="xlarge" style={styles.heading}>
					Scoring
				</LeagueTextLight>
				<View style={styles.scoring}>
					<Text style={[styles.scoringText, styles.instruction]}>
						Momentum cards earn the player potential points towards their milestone
					</Text>
					<Image
						style={{ height: 65.5, width: 126, resizeMode: 'contain' }}
						source={require('../assets/images/instruction_cards1.png')}
					></Image>
				</View>
				<View style={styles.scoring}>
					<Text style={[styles.scoringText, styles.instruction]}>
						One Mayhem card drawn cancels out the potential points of the preceding card
					</Text>
					<Image
						style={{ height: 65.5, width: 152.5, resizeMode: 'contain' }}
						source={require('../assets/images/instruction_cards2.png')}
					></Image>
				</View>
				<View style={styles.scoring}>
					<Text style={[styles.scoringText, styles.instruction]}>
						Two Mayhem cards drawn in a single turn cancels out all of the potential
						points and ends the player’s turn
					</Text>
					<Image
						style={{ height: 65.5, width: 194, resizeMode: 'contain' }}
						source={require('../assets/images/instruction_cards3.png')}
					></Image>
				</View>
				<Text style={{ opacity: 0.8, color: '#fff', fontSize: 12 }}>
					Version: {githubSha}
				</Text>
			</ScrollView>
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1
	},
	heading: {
		marginBottom: 18
	},
	scoring: {
		flexDirection: 'row',
		justifyContent: 'flex-start'
	},
	instruction: {
		marginBottom: 28,
		color: '#fff',
		fontWeight: '400',
		fontSize: 16
	},
	scoringText: {
		width: 300,
		marginRight: 30
	}
});
