import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAlphabet } from 'nanoid/non-secure';
import { getCurrentPlayer, getNextPlayer } from './util';

type GameStatus =
	| 'IDLE'
	| 'WAITING_FOR_PLAYERS'
	| 'STARTING'
	| 'STARTED'
	| 'ENDING'
	| 'ENDED'
	| 'ABORTED'
	| 'GAME_NOT_FOUND';
type TurnStatus = 'STARTED' | 'ENDING' | 'ENDED';
type PlayerType = 'HUMAN' | 'AI';
export type GameType =
	| 'ONLINE_PUBLIC'
	| 'JOIN_ONLINE_PRIVATE'
	| 'HOST_ONLINE_PRIVATE'
	| 'OFFLINE_SINGLE_PLAYER'
	| 'OFFLINE_MULTI_PLAYER';

export const playerNames = [
	'click',
	'cloudia',
	'markus dunn',
	'patches',
	'piper',
	'saasy',
	'sir verless',
	'specs'
];
export type PlayerName = (typeof playerNames)[number];

export type Player = {
	playerId: string;
	score: number;
	type: PlayerType;
	name: PlayerName;
	deviceId: string;
	status: 'ACTIVE' | 'INACTIVE' | 'PAUSED';
};

export type GameState = {
	type?: GameType;
	targetDeviceId?: string;
	seed: number;
	status: GameStatus;
	turnStatus: TurnStatus;
	players: Player[];
	currentPlayer?: string;
	currentCard: number;
	handStartCard: number;
	deviceId: string;
};

export type CreatePayload = {
	gameType: GameType;
	gameCode?: string;
	players: PlayerPayload[];
	rematch?: boolean;
};

export type PlayerPayload = {
	playerId: string;
	name: PlayerName;
	type: 'AI' | 'HUMAN';
	deviceId: string;
};

const deviceId = customAlphabet('ABCDEFGHIJKLMNPQRSTUVWXYZ', 6)();

const initialState: () => GameState = () => ({
	status: 'IDLE',
	type: undefined,
	players: [],
	seed: 0,
	currentCard: 0,
	handStartCard: 0,
	currentPlayer: undefined,
	turnStatus: 'ENDED',
	deviceId
});

const gameSlice = createSlice({
	name: 'game',
	initialState: initialState(),
	reducers: {
		create(_, action: PayloadAction<CreatePayload>) {
			const { gameType, gameCode } = action.payload;
			return {
				...initialState(),
				type: gameType,
				targetDeviceId: gameCode
			};
		},
		addPlayer(state, action: PayloadAction<PlayerPayload>) {
			const { playerId, name, type, deviceId } = action.payload;

			state.players.push({
				name,
				playerId,
				score: 0,
				type,
				deviceId,
				status: 'ACTIVE'
			});
		},
		waitingForPlayers(state) {
			state.status = 'WAITING_FOR_PLAYERS';
		},
		gameNotFound(state) {
			state.status = 'GAME_NOT_FOUND';
		},
		start(state, action: PayloadAction<{ seed: number; playerId: string }>) {
			const { seed, playerId } = action.payload;

			state.currentCard = 0;
			state.handStartCard = 0;
			state.currentPlayer = playerId;
			state.status = 'STARTED';
			state.turnStatus = 'STARTED';
			state.seed = seed;
		},
		requestHit() {},
		requestStick() {},
		requestEndGame() {},
		hit() {},
		stick() {},
		active(state) {
			const currentPlayer = getCurrentPlayer(state);
			currentPlayer.status = 'ACTIVE';
		},
		inactive(state) {
			const currentPlayer = getCurrentPlayer(state);
			currentPlayer.status = 'INACTIVE';
		},
		pause(state) {
			const currentPlayer = getCurrentPlayer(state);
			currentPlayer.status = 'PAUSED';
		},
		resume(state) {
			const currentPlayer = getCurrentPlayer(state);
			currentPlayer.status = 'ACTIVE';
		},
		drawCard(state) {
			state.currentCard = state.currentCard + 1;
		},
		nextTurn(state) {
			state.handStartCard = state.currentCard;
			state.currentPlayer = getNextPlayer(state).playerId;
			state.turnStatus = 'STARTED';
		},
		addToScore(state, action: PayloadAction<number>) {
			const currentPlayer = getCurrentPlayer(state);
			currentPlayer.score = currentPlayer.score + action.payload;
		},
		endGame(state) {
			state.status = 'ENDED';
		},
		endGameEarly(state) {
			state.status = 'ABORTED';
		},
		nextTurnTransitionStart(state) {
			state.turnStatus = 'ENDING';
		},
		nextTurnTransitionEnd(state) {
			state.turnStatus = 'ENDED';
		},
		startGameTransitionStart(state) {
			state.status = 'STARTING';
		},
		startGameTransitionEnd() {},
		endGameTransitionStart(state) {
			state.status = 'ENDING';
		},
		endGameTransitionEnd() {},
		reset() {
			return initialState();
		}
	}
});

export const { actions } = gameSlice;
export default gameSlice.reducer;
