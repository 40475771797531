import { FunctionComponent } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { PlayerName } from './gameSlice';
import { LeagueTextLight } from './Text';

const avatars: Record<PlayerName, any> = {
	click: require('../assets/images/avatars/click.png'),
	cloudia: require('../assets/images/avatars/cloudia.png'),
	['markus dunn']: require('../assets/images/avatars/markus_dunn.png'),
	patches: require('../assets/images/avatars/patches.png'),
	piper: require('../assets/images/avatars/piper.png'),
	saasy: require('../assets/images/avatars/saasy.png'),
	['sir verless']: require('../assets/images/avatars/sir_verless.png'),
	specs: require('../assets/images/avatars/specs.png')
};

interface AvatarProperties {
	name: PlayerName;
	playerNumber?: number;
	size?: 'xsmall' | 'small' | 'medium' | 'large';
	disabled?: boolean;
}

const Avatar: FunctionComponent<AvatarProperties> = ({
	name,
	size = 'medium',
	disabled = false,
	playerNumber
}) => {
	return (
		<View style={[styles.root]}>
			<Image
				source={avatars[name]}
				style={[styles.avatar, styles[size], disabled && styles.disabled]}
			/>
			{disabled && playerNumber && (
				<View style={[styles.player]}>
					<LeagueTextLight size="small">P{playerNumber}</LeagueTextLight>
				</View>
			)}
		</View>
	);
};

export default Avatar;

const styles = StyleSheet.create({
	root: {
		position: 'relative'
	},
	player: {
		position: 'absolute',
		backgroundColor: '#EA585C',
		borderRadius: 200,
		width: 28,
		height: 28,
		zIndex: 5,
		elevation: 5,
		justifyContent: 'center',
		alignItems: 'center',
		right: 0
	},
	avatar: {
		borderRadius: 200,
		borderColor: '#000',
		overflow: 'hidden',
		backgroundColor: '#BCE8EC'
	},
	xsmall: { width: 46, height: 46, borderWidth: 2 },
	small: { width: 56, height: 56, borderWidth: 2 },
	medium: {
		width: 72,
		height: 72,
		borderWidth: 3
	},
	large: {
		width: 125,
		height: 125,
		borderWidth: 5
	},
	disabled: {
		borderColor: '#EA585C'
	}
});
