import { Platform } from 'react-native';
import { useEffect, useState } from 'react';

const getVisibility = () => {
	if (Platform.OS === 'web') {
		return document.visibilityState === 'visible';
	}
	return true;
};

export const usePageVisibility = () => {
	const [isVisible, setIsVisible] = useState(getVisibility());
	useEffect(() => {
		let handler: () => void;
		if (Platform.OS === 'web') {
			const handler = () => {
				setIsVisible(getVisibility());
			};
			document.addEventListener('visibilitychange', handler);
		}
		return () => {
			if (Platform.OS === 'web') {
				document.removeEventListener('visibilitychange', handler);
			}
		};
	}, []);
	return [isVisible];
};
