import { useState, useEffect, useCallback } from 'react';
import { Audio } from 'expo-av';

type Sounds = {
	drawCard: Audio.Sound;
	bust: Audio.Sound;
	mayhemCard: Audio.Sound;
};

export function useSounds(): [Sounds?] {
	const [sounds, setSounds] = useState<Sounds>();

	const loadSounds = useCallback(async () => {
		const sources = {
			drawCard: require('../assets/audio/draw-card.mp3'),
			bust: require('../assets/audio/bust.mp3'),
			mayhemCard: require('../assets/audio/mayhem-card.mp3')
		};

		const loadedSounds: { name: string; sound: Audio.Sound }[] = await Promise.all(
			Object.keys(sources).map(async (key) => {
				const { sound } = await Audio.Sound.createAsync(
					sources[key as keyof typeof sources]
				);
				return { name: key, sound };
			})
		);
		setSounds(
			loadedSounds.reduce((map, value) => {
				map[value.name] = value.sound;
				return map;
			}, {} as any)
		);
	}, []);

	useEffect(() => {
		loadSounds();
	}, []);

	return [sounds];
}
