import { RealtimeChannel } from '@supabase/supabase-js';
import { Channel } from 'redux-saga';
import Constants from 'expo-constants';
import { customAlphabet } from 'nanoid/non-secure';

import { client, createChannel } from './client';
import { PlayerPayload, GameType, playerNames } from './gameSlice';
import { isOnlineType } from './util';

interface AddPlayerPayload {
	type: 'ADD_PLAYER';
	payload: PlayerPayload;
}

interface EndGameEarlyPayload {
	type: 'END_GAME_EARLY';
}

interface GameNotFoundPayload {
	type: 'GAME_NOT_FOUND';
}

interface StartGamePayload {
	type: 'START_GAME';
	payload: {
		seed: number;
	};
}

interface GameActionPayload {
	type: 'GAME_ACTION';
	payload: 'hit' | 'stick';
}

interface WaitingForPlayersPayload {
	type: 'WAITING_FOR_PLAYERS';
}

export type ChannelPayload =
	| AddPlayerPayload
	| EndGameEarlyPayload
	| GameNotFoundPayload
	| StartGamePayload
	| GameActionPayload
	| WaitingForPlayersPayload;

interface ServerOptions {
	gameType: GameType;
	deviceId: string;
	channel: Channel<ChannelPayload>;
	targetDeviceId?: string;
	rematch?: boolean;
}

interface JoinAcceptedPayload {
	players: PlayerPayload[];
	deviceId: string;
	id: string;
}

interface JoinPayload {
	players: PlayerPayload[];
	deviceId: string;
}

// Event Topics
const JOIN_ACCEPTED = 'JOIN_GAME_ACCEPTED-';
const JOIN = 'JOIN_GAME';
const START = 'START_GAME-';

const JOIN_PRIVATE_GAME_TIMEOUT_DURATION = 5 * 1000;
const MIN_AI_FALLBACK_TIMEOUT_DURATION = 10 * 1000;
const AI_PLAYERS_PER_DEVICE_SINGLE_PLAYER = 1;
const VERSION = Constants.expoConfig?.extra?.GITHUB_SHA;

function random(max: number, min: number = 0) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

function createAIPlayer(players: PlayerPayload[]) {
	const [{ name: playerName }] = players;
	const availableNames = playerNames.filter((name) => name !== playerName);
	const name = availableNames[random(availableNames.length - 1)];
	const id = `AI-${players.length}`;
	return {
		name,
		playerId: id,
		type: 'AI',
		deviceId: id
	} as const;
}

export default class Server {
	private id = customAlphabet('ABCDEFGHIJKLMNOP123456789', 6)();
	private lobby?: RealtimeChannel;
	private game?: RealtimeChannel;
	private players: PlayerPayload[] = [];
	private isOnline: boolean;
	private locked = false;
	private joinTimeout?: number;
	private hostTimeout?: number;

	constructor(private options: ServerOptions) {
		this.isOnline = isOnlineType(options.gameType);

		if (this.isOnline) {
			this.lobby = createChannel(`lobby-${VERSION}`, {
				config: {
					presence: {
						key: this.options.deviceId
					}
				}
			});
		}
	}

	private joinAcceptedEventListener = (payload: JoinAcceptedPayload) => {
		this.locked = true;
		clearTimeout(this.joinTimeout);

		const { players, deviceId, id } = payload;
		const sortedDevices = [this.options.deviceId, deviceId].sort();
		const [startingDeviceId] = sortedDevices;

		players.forEach((player: PlayerPayload) => {
			this.send({
				type: 'ADD_PLAYER',
				payload: player
			});
		});

		this.subscribe(id);

		if (startingDeviceId === this.options.deviceId) {
			this.start();
		} else {
			this.lobby?.send({
				type: 'broadcast',
				event: `${START}${id}`,
				payload: {
					deviceId: this.options.deviceId,
					players: this.players
				}
			});
		}
	};

	private joinEventListener = (payload: JoinPayload) => {
		const { deviceId, players } = payload;
		clearTimeout(this.hostTimeout);
		if (!this.locked) {
			this.locked = true;
			const sortedDevices = [this.options.deviceId, deviceId].sort();

			this.lobby?.on('broadcast', { event: `${START}${this.id}` }, () => {
				const [startingDeviceId] = sortedDevices;
				if (startingDeviceId === this.options.deviceId) {
					this.start();
				}
			});

			this.subscribe(this.id);

			this.lobby?.send({
				type: 'broadcast',
				event: `${JOIN_ACCEPTED}${deviceId}`,
				payload: {
					deviceId: this.options.deviceId,
					players: this.players,
					id: this.id
				}
			});

			players.forEach((player) => {
				this.send(
					{
						type: 'ADD_PLAYER',
						payload: player
					},
					{ remote: false }
				);
			});
		}
	};

	private sendJoinEvent() {
		const { deviceId, targetDeviceId } = this.options;
		const event = targetDeviceId ? `${JOIN}-${targetDeviceId}` : JOIN;
		this.lobby?.send({
			type: 'broadcast',
			event,
			payload: {
				deviceId: deviceId,
				players: this.players
			}
		});
	}

	private addPlayer(player: PlayerPayload) {
		this.players.push(player);
		this.send(
			{
				type: 'ADD_PLAYER',
				payload: player
			},
			{ remote: false }
		);
	}

	private send(
		payload: ChannelPayload,
		{ local = true, remote = true }: { local?: boolean; remote?: boolean } = {}
	) {
		if (local) {
			this.options.channel.put(payload);
		}

		if (remote) {
			return this.game?.send({
				type: 'broadcast',
				event: 'ACTION',
				payload
			});
		}
	}

	private subscribe(gameId: string) {
		if (this.isOnline) {
			this.game = createChannel(`game-${gameId}`, {
				config: {
					presence: { key: this.options.deviceId }
				}
			});

			this.game
				.on('broadcast', { event: 'ACTION' }, ({ payload }) => {
					this.send(payload, { remote: false });
				})
				.on('presence', { event: 'leave' }, () => {
					this.send({ type: 'END_GAME_EARLY' }, { remote: false });
				})
				.subscribe((status) => {
					if (status === 'SUBSCRIBED') {
						this.game?.track({ deviceId: this.options.deviceId });
					}
				});
		}
	}

	private join() {
		const { deviceId, targetDeviceId, rematch } = this.options;
		if (targetDeviceId && !rematch) {
			this.joinTimeout = setTimeout(() => {
				this.send({ type: 'GAME_NOT_FOUND' }, { remote: false });
			}, JOIN_PRIVATE_GAME_TIMEOUT_DURATION) as unknown as number;
		}

		this.lobby?.on('broadcast', { event: `${JOIN_ACCEPTED}${deviceId}` }, (event) => {
			this.joinAcceptedEventListener(event.payload);
		});

		if (rematch && targetDeviceId) {
			let joinRequestSent = false;
			this.lobby?.on('presence', { event: 'sync' }, () => {
				if (!joinRequestSent) {
					const presenceState = this.lobby?.presenceState() || {};
					const games = Object.keys(presenceState);
					if (games.indexOf(targetDeviceId) > -1) {
						joinRequestSent = true;
						this.sendJoinEvent();
					}
				}
			});
		} else {
			this.sendJoinEvent();
		}
	}

	private host() {
		const { gameType, deviceId } = this.options;
		const event = gameType === 'HOST_ONLINE_PRIVATE' ? `${JOIN}-${deviceId}` : JOIN;

		if (gameType !== 'HOST_ONLINE_PRIVATE') {
			const variableDuration = random(5) * 1000;
			this.hostTimeout = setTimeout(() => {
				if (!this.locked) {
					this.locked = true;
					const aiPlayer = createAIPlayer(this.players);
					this.addPlayer(aiPlayer);
					this.subscribe(this.id);
					this.start();
				}
			}, MIN_AI_FALLBACK_TIMEOUT_DURATION + variableDuration) as unknown as number;
		}

		this.lobby?.on('broadcast', { event }, (event) => {
			this.joinEventListener(event.payload);
		});
	}

	private start() {
		const seed = Math.ceil(Math.random() * 100);

		this.send({
			type: 'START_GAME',
			payload: {
				seed
			}
		});
	}

	public hit() {
		return this.send({ type: 'GAME_ACTION', payload: 'hit' });
	}

	public stick() {
		return this.send({ type: 'GAME_ACTION', payload: 'stick' });
	}

	public addPlayers(players: PlayerPayload[]) {
		players.forEach((player) => {
			this.addPlayer(player);
		});

		if (this.options.gameType === 'OFFLINE_SINGLE_PLAYER') {
			for (let i = 0; i < AI_PLAYERS_PER_DEVICE_SINGLE_PLAYER; i += 1) {
				const aiPlayer = createAIPlayer(players);
				this.addPlayer(aiPlayer);
			}
		} else if (this.isOnline) {
			this.options.channel.put({ type: 'WAITING_FOR_PLAYERS' });
		}
	}

	public connect() {
		// offline game will not have a lobby channel
		this.lobby?.subscribe((status) => {
			if (status === 'SUBSCRIBED') {
				this.lobby?.track({ deviceId: this.options.deviceId });
			}
		});

		const { gameType } = this.options;
		switch (gameType) {
			case 'OFFLINE_MULTI_PLAYER':
			case 'OFFLINE_SINGLE_PLAYER':
				this.start();
				break;
			case 'JOIN_ONLINE_PRIVATE':
				this.join();
				break;
			case 'HOST_ONLINE_PRIVATE':
				this.host();
				break;
			default:
				this.join();
				this.host();
				break;
		}
	}

	public endGameEarly() {
		return this.send({ type: 'END_GAME_EARLY' }, { local: false });
	}

	public async destroy() {
		if (this.isOnline) {
			if (this.game) {
				await this.endGameEarly();
			}
		}
		client.removeAllChannels();
	}
}
