import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity, Text } from 'react-native';
import Animated, {
	runOnJS,
	useAnimatedStyle,
	useSharedValue,
	withTiming
} from 'react-native-reanimated';
import { SamdanTextLight, LeagueText, LeagueTextLight } from './Text';
import { A } from '@expo/html-elements';
import promoImage from '../assets/images/promoImage.png';

interface PromoProperties {
	onMainMenu(): void;
}

export default ({ onMainMenu }: PromoProperties) => {
	const fadeInFromBlackOpacity = useSharedValue(1);
	const [fadePromoIn, setFadePromoIn] = useState(true);

	useEffect(() => {
		fadeInFromBlackOpacity.value = withTiming(0, { duration: 300 }, () =>
			runOnJS(setFadePromoIn)(false)
		);
	}, []);

	const fadeInPromoStyle = useAnimatedStyle(() => {
		return {
			opacity: fadeInFromBlackOpacity.value
		};
	});

	return (
		<>
			<View style={styles.wrapper}>
				<Image style={styles.image} source={promoImage} />
				<View style={styles.blurb}>
					<SamdanTextLight size="xxlarge">Avoid the Mayhem</SamdanTextLight>
					<Text style={styles.body}>
						Whether it’s fullstack feature development, legacy re-engineering, or a new
						proof-of-concept, avoid the mayhem by building it with us.
					</Text>
					<View style={styles.buttonRow}>
						<A
							href="https://www.sitepen.com"
							target="_blank"
							style={{ marginRight: 16 }}
						>
							<View style={[styles.button, styles.branded]}>
								<LeagueTextLight>Let’s work together</LeagueTextLight>
							</View>
						</A>
						<TouchableOpacity style={styles.button} onPress={onMainMenu}>
							<LeagueText>Main Menu</LeagueText>
						</TouchableOpacity>
					</View>
				</View>
			</View>
			{fadePromoIn && (
				<Animated.View style={[styles.blackCover, fadeInPromoStyle]}></Animated.View>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	image: {
		maxWidth: 460,
		maxHeight: 328,
		resizeMode: 'contain',
		height: '100%',
		width: '100%',
		flex: 1,
		marginRight: 10
	},
	wrapper: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-around',
		flex: 1,
		width: '100%',
		height: '100%'
	},
	blurb: { flex: 1, marginLeft: 10 },
	body: { color: '#fff', fontSize: 16, lineHeight: 24, marginBottom: 18, marginTop: 24 },
	button: {
		paddingHorizontal: 16,
		paddingVertical: 8,
		borderRadius: 42,
		backgroundColor: '#ECF8FA'
	},
	branded: { backgroundColor: '#35B4C1' },
	buttonRow: {
		flexDirection: 'row',
		marginTop: 16
	},
	blackCover: {
		backgroundColor: '#000',
		position: 'absolute',
		left: -1000,
		right: -1000,
		top: -1000,
		bottom: -1000,
		elevation: 100,
		zIndex: 100
	}
});
