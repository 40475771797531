import AsyncStorage from '@react-native-async-storage/async-storage';
import {
	createClient,
	RealtimeChannelOptions,
	RealtimeChannelSendResponse
} from '@supabase/supabase-js';
import { pause } from './util';

const isLocal = process.env.NODE_ENV === 'development';
const apiUrl = isLocal ? 'http://localhost:54321' : 'https://hnkmgrhonkshxnwgwtjm.supabase.co';
const anonKey = isLocal
	? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0'
	: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imhua21ncmhvbmtzaHhud2d3dGptIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjYwMTgzNTUsImV4cCI6MTk4MTU5NDM1NX0.txr_SH2bkgv2pCWvHHmZVlKyu2kMeCs9hYwSUdI4MXo';

export const client = createClient(apiUrl, anonKey, {
	auth: {
		storage: AsyncStorage,
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: false
	},
	realtime: { params: { eventsPerSecond: 20000 } }
});

export const createChannel = (channelId: string, config?: RealtimeChannelOptions) => {
	const channel = client.channel(channelId, config);
	const originalSend = channel.send.bind(channel);
	let queue: { resolver: any; params: Parameters<typeof originalSend> }[] = [];
	let running = false;

	const run = async () => {
		running = true;
		while (queue.length) {
			const { resolver, params } = queue.pop()!;
			let result: RealtimeChannelSendResponse | undefined;
			let counter = 0;
			while (result !== 'ok' && counter < 10) {
				counter++;
				await pause(counter * 5);
				result = await originalSend(...params);
				// TODO: Is 'rate limited' a valid response? The supabase typings don't include
				// that, just 'ok', 'timed out', and 'error'.
				// @ts-ignore
				if (result === 'rate limited') {
					console.warn('channel.send has been rate limited ', params, counter);
				}
				resolver(result);
			}
		}
		running = false;
	};

	channel.send = (...params: Parameters<typeof originalSend>) => {
		let resolver;
		const queuedSend = new Promise<RealtimeChannelSendResponse>((resolve) => {
			resolver = resolve;
		});
		queue.unshift({ resolver, params });
		if (!running) {
			run();
		}

		return queuedSend;
	};
	return channel;
};
