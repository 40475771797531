import {
	take,
	fork,
	call,
	race,
	select,
	put,
	delay,
	cancel,
	cancelled,
	actionChannel
} from 'redux-saga/effects';
import { RootState } from './store';
import {
	getCurrentPlayer,
	getOtherPlayers,
	getHandScore,
	hasMayhemCardInHand,
	isAI,
	isEmptyDeck,
	isOnlineType,
	getFirstPlayerId
} from './util';
import { actions, PlayerPayload, Player } from './gameSlice';
import { Channel, channel, Task, SagaIterator } from 'redux-saga';
import Server, { ChannelPayload } from './Server';

function* aiSaga(): SagaIterator {
	while (true) {
		const { game }: RootState = yield select();
		const handScore = getHandScore(game);
		const aiScore = getCurrentPlayer(game).score;
		const highScore = getOtherPlayers(game).reduce((score: number, player: Player) => {
			return player.score > score ? player.score : score;
		}, 0);
		const targetHandScore = aiScore < highScore ? 4 : 2;
		const hasMayhemCard = hasMayhemCardInHand(game);
		const stick = hasMayhemCard && handScore > targetHandScore;
		const aiDelay = handScore && hasMayhemCard ? Math.floor(Math.random() * 1500) + 1000 : 1000;
		yield delay(aiDelay);
		if (stick) {
			yield put(actions.requestStick());
		} else {
			yield put(actions.requestHit());
			yield take(actions.drawCard);
		}
	}
}

function* lobbySaga(serverChannel: Channel<{ type: string; payload?: any }>): SagaIterator {
	while (true) {
		const { reset, incomingMessage } = yield race({
			reset: take(actions.reset),
			incomingMessage: take(serverChannel)
		});
		if (reset) {
			break;
		} else if (incomingMessage?.type === 'ADD_PLAYER') {
			yield put(actions.addPlayer(incomingMessage.payload));
		} else if (incomingMessage?.type === 'WAITING_FOR_PLAYERS') {
			yield put(actions.waitingForPlayers());
		} else if (incomingMessage?.type === 'START_GAME') {
			yield put(actions.startGameTransitionStart());
			yield take(actions.startGameTransitionEnd);
			const {
				game: { players, type }
			}: RootState = yield select();
			const {
				payload: { seed }
			} = incomingMessage;
			const firstPlayerId =
				type === 'OFFLINE_SINGLE_PLAYER'
					? players[0].playerId
					: getFirstPlayerId(players, seed);
			yield put(actions.start({ seed, playerId: firstPlayerId }));
			break;
		} else if (incomingMessage?.type === 'GAME_NOT_FOUND') {
			yield put(actions.gameNotFound());
			break;
		}
	}
}

function* inGameSaga(
	server: Server,
	serverChannel: Channel<{ type: string; payload?: any }>
): SagaIterator {
	while (true) {
		const { requestHit, requestStick, requestEndGame, incomingMessage } = yield race({
			requestHit: take(actions.requestHit),
			requestStick: take(actions.requestStick),
			requestEndGame: take(actions.requestEndGame),
			incomingMessage: take(serverChannel)
		});
		if (requestHit) {
			yield call(() => server.hit());
		} else if (requestStick) {
			yield call(() => server.stick());
		} else if (requestEndGame) {
			yield call(() => server.endGameEarly());
			yield put(actions.reset());
			break;
		} else if (incomingMessage?.type === 'GAME_ACTION') {
			if (incomingMessage.payload === 'hit') {
				yield put(actions.hit());
			} else if (incomingMessage.payload === 'stick') {
				yield put(actions.stick());
			}
		} else if (incomingMessage?.type === 'END_GAME_EARLY') {
			yield put(actions.endGameEarly());
			break;
		}
	}
}

function* serverSaga(players: PlayerPayload[], rematch?: boolean): SagaIterator {
	const {
		game: { type, deviceId, targetDeviceId }
	}: RootState = yield select();
	const serverChannel: Channel<ChannelPayload> = yield call(channel);
	const server = new Server({
		channel: serverChannel,
		gameType: type!,
		deviceId,
		targetDeviceId,
		rematch
	});
	server.addPlayers(players);
	server.connect();

	try {
		yield call(lobbySaga, serverChannel);
		yield call(inGameSaga, server, serverChannel);
	} finally {
		if (yield cancelled()) {
			server.destroy();
		}
	}
}

function isWinner(game: any) {
	const currentPlayer = getCurrentPlayer(game);
	return currentPlayer.score > 19;
}

function isBust(game: any) {
	const handScore = getHandScore(game);
	return handScore === -1;
}

function isGameOver(game: any) {
	return (
		isWinner(game) || isEmptyDeck(game) || game.status === 'ENDED' || game.status === 'ABORTED'
	);
}

function isTurnOver(game: any) {
	return isBust(game) || isWinner(game) || isEmptyDeck(game);
}

function handScore(game: any) {
	const handScore = getHandScore(game);
	return Math.max(handScore, 0);
}

function* gameSaga(): SagaIterator {
	while (true) {
		const { game }: RootState = yield select();
		let ai: Task | undefined;
		if (isAI(game)) {
			ai = yield fork(aiSaga);
		}

		yield call(turnSaga);

		if (ai) {
			yield cancel(ai);
		}

		{
			yield put(actions.nextTurnTransitionStart());
			yield take(actions.nextTurnTransitionEnd);
			const { game }: RootState = yield select();
			yield put(actions.addToScore(handScore(game)));
		}
		{
			const { game }: RootState = yield select();
			if (isGameOver(game)) {
				yield put(actions.endGameTransitionStart());
				yield take(actions.endGameTransitionEnd);
				yield put(actions.endGame());
				return;
			}
		}
		yield put(actions.nextTurn());
	}
}

function* inactivitySaga(): SagaIterator {
	yield delay(5000);
	yield put(actions.inactive());
	yield delay(5000);
	yield put(actions.requestStick());
}

function* turnSaga(): SagaIterator {
	const channel = yield actionChannel([actions.hit, actions.stick, actions.pause]);

	while (true) {
		yield put(actions.active());
		const { game }: RootState = yield select();

		let inactivity: Task | undefined;
		if (isOnlineType(game.type) && getCurrentPlayer(game).deviceId === game.deviceId) {
			inactivity = yield fork(inactivitySaga);
		}

		const result = yield take(channel);

		if (inactivity) {
			yield cancel(inactivity);
		}

		if (actions.pause.match(result)) {
			yield take(actions.resume);
		} else {
			yield put(actions.active());
		}

		if (actions.hit.match(result)) {
			yield put(actions.drawCard());
		}

		{
			const { game }: RootState = yield select();
			if (
				actions.stick.match(result) ||
				isTurnOver(game) ||
				isGameOver(game) ||
				getHandScore(game) + getCurrentPlayer(game).score > 19
			) {
				return;
			}
		}
	}
}

export function* rootSaga(): SagaIterator {
	while (true) {
		const {
			payload: { players, rematch }
		} = yield take(actions.create);

		const server: Task = yield fork(() => serverSaga(players, rematch));

		const { start } = yield race({
			start: take(actions.start),
			reset: take(actions.reset)
		});
		if (start) {
			yield race([call(gameSaga), take(actions.reset)]);
		}
		yield cancel(server);
	}
}
