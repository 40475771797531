import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Animated, {
	useSharedValue,
	withSpring,
	useAnimatedStyle,
	withDelay,
	withTiming,
	runOnJS
} from 'react-native-reanimated';
import { LeagueText, LeagueTextLight, SamdanTextLight } from './Text';
import trophyImage from '../assets/images/trophy.png';
import trashImage from '../assets/images/trash.png';

export type GameOverType = 'WIN' | 'LOSE' | 'EARLY';

interface GameOverProps {
	onContinue(): void;
	onAction(): void;
	type: GameOverType;
	action: 'rematch' | 'play-again';
}

export default (props: GameOverProps) => {
	const { onContinue, type, onAction, action } = props;

	const resultScale = useSharedValue(0);
	const continueFade = useSharedValue(0);

	const [fadeToBlack, setFadeToBlack] = useState(false);

	useEffect(() => {
		resultScale.value = withSpring(1, { stiffness: 250 });
		continueFade.value = withDelay(1000, withTiming(1, { duration: 500 }));
	}, [type]);

	const fadeToBlackOpacity = useSharedValue(0);
	useEffect(() => {
		if (fadeToBlack) {
			fadeToBlackOpacity.value = withTiming(1, { duration: 300 }, () =>
				runOnJS(onContinue)()
			);
		} else {
			fadeToBlackOpacity.value = 0;
		}
	}, [fadeToBlack]);

	const fadeToBlackStyle = useAnimatedStyle(() => {
		return {
			opacity: fadeToBlackOpacity.value
		};
	});

	const animatedResultStyle = useAnimatedStyle(() => {
		return {
			transform: [{ scale: resultScale.value }]
		};
	});
	const fadeInButtonStyle = useAnimatedStyle(() => {
		return { opacity: continueFade.value };
	});

	return (
		<View style={styles.root}>
			{(type === 'WIN' || type === 'EARLY') && (
				<Animated.View style={[styles.animatedRoot, animatedResultStyle]}>
					<Image source={trophyImage} style={styles.trophy} />
					<View>
						<SamdanTextLight size="xxlarge">Success</SamdanTextLight>
						<LeagueTextLight size="medium">
							{type === 'EARLY'
								? `Your opponent ended their sprint early!`
								: `You’ve avoided the mayhem!`}
						</LeagueTextLight>
					</View>
				</Animated.View>
			)}
			{type === 'LOSE' && (
				<Animated.View style={[styles.animatedRoot, animatedResultStyle]}>
					<Image source={trashImage} style={styles.trash} />
					<View>
						<SamdanTextLight size="xxlarge">You've Failed</SamdanTextLight>
						<LeagueTextLight size="medium">Your Project’s been canned</LeagueTextLight>
					</View>
				</Animated.View>
			)}
			<Animated.View style={[styles.buttonWrapper, fadeInButtonStyle]}>
				<View style={styles.buttonRow}>
					<TouchableOpacity
						onPress={onAction}
						style={[styles.button, styles.branded, { marginRight: 16 }]}
					>
						<LeagueTextLight size="small">
							{action === 'play-again' ? 'Play Again' : 'Rematch'}
						</LeagueTextLight>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setFadeToBlack(true);
						}}
						style={styles.button}
					>
						<LeagueText size="small">Continue</LeagueText>
					</TouchableOpacity>
				</View>
			</Animated.View>
			{fadeToBlack && (
				<Animated.View style={[styles.blackCover, fadeToBlackStyle]}></Animated.View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	root: {
		flex: 1,
		alignItems: 'center'
	},
	animatedRoot: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row'
	},
	trophy: {
		width: 142,
		height: 132,
		marginRight: 16
	},
	trash: {
		width: 182,
		height: 131,
		marginRight: 16
	},
	blackCover: {
		backgroundColor: '#000',
		position: 'absolute',
		left: -1000,
		right: -1000,
		top: -1000,
		bottom: -1000,
		elevation: 100,
		zIndex: 100
	},
	buttonWrapper: {
		flexDirection: 'row',
		alignSelf: 'flex-end'
	},
	branded: { backgroundColor: '#35B4C1' },
	buttonRow: {
		flexDirection: 'row',
		marginTop: 16
	},
	button: {
		backgroundColor: '#ECF8FA',
		borderRadius: 40,
		paddingHorizontal: 24,
		paddingVertical: 8
	}
});
